import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
import ProjectBox from "../Elements/ProjectBox";
// Assets
import AddImage1 from "../../assets/img/add/1.png";
import AddImage2 from "../../assets/img/add/2.png";
import AddImage3 from "../../assets/img/add/3.png";
import AddImage4 from "../../assets/img/add/4.png";
import ProjectImg2 from "../../assets/img/service/xiaoshimo.png";
import ProjectImg3 from "../../assets/img/service/kexing.png";
import Hanjie1 from "../../assets/img/service/hanjie1.png";
import Hanjie2 from "../../assets/img/service/hanjie2.png";
import Hanjie3 from "../../assets/img/service/hanjie3.png";
import Hanjie4 from "../../assets/img/service/hanjie4.png";
import Youse1 from "../../assets/img/service/youse1.png";
import Youse2 from "../../assets/img/service/youse2.png";
import Youse3 from "../../assets/img/service/youse3.png";
import Youse4 from "../../assets/img/service/youse4.png";
import Youse5 from "../../assets/img/service/youse5.png";
import Youse6 from "../../assets/img/service/youse6.png";
import Ji1 from "../../assets/img/service/ji1.png";
import Ji2 from "../../assets/img/service/ji2.png";
import Ji3 from "../../assets/img/service/ji3.png";
import Ji4 from "../../assets/img/service/ji4.png";
import Ji5 from "../../assets/img/service/ji5.png";
import Ji6 from "../../assets/img/service/ji6.png";
import Equip1 from "../../assets/img/service/equipment1.png";
import Equip2 from "../../assets/img/service/equipment2.png";
import Equip3 from "../../assets/img/service/equipment3.png";
import Equip4 from "../../assets/img/service/equipment4.png";


export default function Services() {
    return (
        <Wrapper id="services">
            <div className="lightBg" style={{padding: "50px 0"}}>
                <div className="container">
                    {/*<ClientSlider/>*/}
                </div>
            </div>
            <div className="whiteBg" style={{padding: "60px 0"}}>
                <div className="container">
                    <HeaderInfo>
                        <h1 className="font40 extraBold">主要产品与设备</h1>
                        <p className="font13">
                            我们生产风力发电设备铸件配件、电梯曳引机铸件、变速箱箱体铸件、装载机、载重机、制碱设备的铸件配件，还生产各类泵体、阀体、阀板、纺织机械铸件及汽车模具的制作
                            <br/>

                        </p>
                    </HeaderInfo>
                    <ServiceBoxRow className="flex">
                        <ServiceBoxWrapper>
                            <ServiceBox
                                icon="roller"
                                title="铸造车间"
                                subtitle="树脂砂车间现有4台电炉，1台1吨、1台1.5吨、1台3吨；1台8吨保温炉;壳型生产线1条，射芯机 10台;树脂砂生产线1条；消失型V法真空浇注生产线1条;喷漆车间1条喷漆生产线"
                            />
                        </ServiceBoxWrapper>
                        <ServiceBoxWrapper>
                            <ServiceBox
                                icon="monitor"
                                title="焊接车间"
                                subtitle="数控切割机一台ERGOR EXA 60;焊接工作台两个;天车4台 50T 1台 32T 1台 10T 2台;环保设备一条线"
                            />
                        </ServiceBoxWrapper>
                        <ServiceBoxWrapper>
                            <ServiceBox
                                icon="browser"
                                title="有色车间"
                                subtitle="本车间以生产齿轮泵等承压铸件见长，具有金属型重力铸造、压力铸造、低压铸造和砂型铸造工艺能力；配备有理化室，检测手段完善，为原材料进厂和炉前合金的检验提供了保障，以生产出质优产品。也具有经验丰富的操作工人，专业的工程技术人员以及成熟的生产管理模式，具备了为各行各业客户服务的能力"
                            />
                        </ServiceBoxWrapper>
                        <ServiceBoxWrapper>
                            <ServiceBox icon="printer" title="机加工车间"
                                        subtitle="海天数控龙门铣床,牧野卧式加工中心,瑞典萨耀卧式加工中心,油机立式数控车床,牧野卧式加工中心,海科特卧式加工中心"/>
                        </ServiceBoxWrapper>
                    </ServiceBoxRow>
                    <h4 className="font40 extraBold">铸造车间生产线</h4>
                    <div className="row textCenter">

                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <ProjectBox
                                img={ProjectImg2}
                                title="消失模生产线"
                                text="Lose foam casting production line"
                            />
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <ProjectBox
                                img={ProjectImg3}
                                title="壳型生产线"
                                text="Shell mould production line"
                            />
                        </div>
                    </div>
                    <div className="container" style={{marginTop:'50px'}}>
                        <h4 className="font40 extraBold">焊接车间生产线</h4>
                        <div className="row textCenter">

                            <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                                <ProjectBox
                                    img={Hanjie1}
                                    title=""
                                    text=""
                                />
                            </div>
                            <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                                <ProjectBox
                                    img={Hanjie2}
                                    title=""
                                    text=""
                                />
                            </div>
                            <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                                <ProjectBox
                                    img={Hanjie3}
                                    title=""
                                    text=""
                                />
                            </div>
                            <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                                <ProjectBox
                                    img={Hanjie4}
                                    title=""
                                    text=""
                                />
                            </div>
                        </div>
                    </div>
                    <div className="container" style={{marginTop:'50px'}}>
                        <h4 className="font40 extraBold">有色金属车间</h4>
                        <div className="row textCenter">

                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <ProjectBox
                                    img={Youse1}
                                    title="热处理炉"
                                    text="Heat treatment furnace"
                                />
                            </div>
                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <ProjectBox
                                    img={Youse2}
                                    title="1T电炉"
                                    text="1T medium frequency electric furnace"
                                />
                            </div>
                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <ProjectBox
                                    img={Youse3}
                                    title="低压铸造机"
                                    text="Low pressure casting machine"
                                />
                            </div>
                        </div>
                        <div className="row textCenter">

                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <ProjectBox
                                    img={Youse4}
                                    title="卧式离心机"
                                    text="Horizontal centrifuge"
                                />
                            </div>
                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <ProjectBox
                                    img={Youse5}
                                    title="倾转式浇注机"
                                    text="Tilting pouring machines"
                                />
                            </div>
                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <ProjectBox
                                    img={Youse6}
                                    title="立式离心机"
                                    text="Vertical centrifuge"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="container" style={{marginTop:'50px'}}>
                        <h4 className="font40 extraBold">机加工车间</h4>
                        <div className="row textCenter">

                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <ProjectBox
                                    img={Ji1}
                                    title="海天数控龙门铣床"
                                    text="4m CNC milling machine"
                                />
                            </div>
                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <ProjectBox
                                    img={Ji2}
                                    title="牧野卧式加工中心"
                                    text="Makino A99e"
                                />
                            </div>
                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <ProjectBox
                                    img={Ji3}
                                    title="瑞典萨耀卧式加工中心"
                                    text="SAJO 10000P"
                                />
                            </div>
                        </div>
                        <div className="row textCenter">

                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <ProjectBox
                                    img={Ji4}
                                    title="油机立式数控车床"
                                    text="YOUJI VT2500ATC"
                                />
                            </div>
                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <ProjectBox
                                    img={Ji5}
                                    title="牧野卧式加工中心"
                                    text="Makino A81"
                                />
                            </div>
                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <ProjectBox
                                    img={Ji6}
                                    title="海科特卧式加工中心"
                                    text="Heckert HEC 1600"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lightBg">
                    <div className="container">
                        <Advertising className="flexSpaceCenter">
                            <AddLeft>
                                <h4 className="font15 semiBold">2023</h4>
                                <h2 className="font40 extraBold">公司的质量目标</h2>
                                <p className="font12">
                                    客户投诉率：0.2%
                                    交货达成率：100%
                                    产品一次交验合格率：99.5%
                                </p>
                                <ButtonsRow className="flexNullCenter" style={{margin: "30px 0"}}>
                                    <div style={{width: "190px"}}>
                                        {/*<FullButton title="Get Started" action={() => alert("clicked")}/>*/}
                                    </div>
                                    <div style={{width: "190px", marginLeft: "15px"}}>
                                        {/*<FullButton title="Contact Us" action={() => alert("clicked")} border/>*/}
                                    </div>
                                </ButtonsRow>
                            </AddLeft>
                            <AddRight>
                                <AddRightInner>
                                    <div className="flexNullCenter">
                                        <AddImgWrapp1 className="flexCenter">
                                            <img src={Equip1} alt="office"/>
                                        </AddImgWrapp1>
                                        <AddImgWrapp2>
                                            <img src={Equip2} alt="office"/>
                                        </AddImgWrapp2>
                                    </div>
                                    <div className="flexNullCenter">
                                        <AddImgWrapp3>
                                            <img src={Equip3} alt="office"/>
                                        </AddImgWrapp3>
                                        <AddImgWrapp4>
                                            <img src={Equip4} alt="office"/>
                                        </AddImgWrapp4>
                                    </div>
                                </AddRightInner>
                            </AddRight>
                        </Advertising>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;

  p {
    max-width: 475px;
  }

  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }

    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;

  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;

  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;

  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5% auto;

  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;