import * as React from "react";

function SvgComponent(props) {
  return (
      <svg t="1682675493338" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
           p-id="9169" width="27" height="40">
          <path
              d="M849.16992 1.28c48.3456 0 91.73504 19.21024 123.03616 50.51392 30.92992 30.92736 50.05568 73.65888 50.50624 121.31072l0.00768 1.72544v674.33984c0 48.3456-19.21024 91.73504-50.51392 123.03616-30.92736 30.92992-73.65888 50.05568-121.31072 50.50624l-1.72544 0.00768H174.83008c-48.3456 0-91.73504-19.21024-123.03616-50.51392C20.864 941.27872 1.73824 898.5472 1.28768 850.89536l-0.00768-1.72544V174.83008c0-48.3456 19.21024-91.73504 50.51392-123.03616C82.72128 20.864 125.4528 1.73824 173.10464 1.28768L174.83008 1.28h674.33984zM532.69248 244.1984c-12.8512-6.29248-27.81952-6.2976-41.56672 0.4608L230.14656 368.6912l-1.08032 0.46592c-8.22528 3.6864-14.99136 9.60512-19.82464 16.91136-5.08928 7.6928-8.0384 16.92672-8.2816 27.70944v196.4288l0.01536 1.22112c0.23552 9.344 2.96704 18.19904 7.69536 25.6768 4.89728 7.74144 11.9296 14.01344 21.4656 18.2016l260.98688 124.032 0.9216 0.4608c12.97408 6.29248 27.9168 6.29504 41.56416-0.4608l260.97408-124.02688 1.03936-0.44288c8.26368-3.64544 15.0528-9.55648 19.8912-16.8704 5.09952-7.70304 8.0384-16.96256 8.25856-27.77856v-160.24576l-0.01792-1.1904c-0.32256-12.3392-5.20704-25.088-13.26848-35.88352-9.25696-9.49504-21.6832-15.5392-37.0816-15.89248H689.408v64.58624h67.19488v137.46432L512.36608 714.752l-244.2368-115.69152v-174.10816l244.24448-113.1264 97.69984 43.70944 0.73728 0.28672c5.45792 2.07872 12.17536 3.46624 20.5184 2.39872h145.23648L533.6064 244.6592z m13.57824 147.5072l-104.96256 50.0352v138.68032l107.5712 49.28768 104.32256-49.43872v-138.6752l-106.9312-49.89184z m-205.75488 7.09376v196.69248l64.58624 32.29184v-196.68992l-64.58624-32.2944z m206.73792 62.9248l46.53312 21.49888v60.13696l-46.53312 21.504-46.53056-21.504V483.2256l46.53056-21.50144z"
              fill="#648CFE" p-id="9170"></path>
      </svg>
  );
}

export default SvgComponent;
