import React from "react";
import styled from "styled-components";
// Assets
import ContactImg1 from "../../assets/img/contact1.png";
import ContactImg2 from "../../assets/img/contact2.png";
import ContactImg3 from "../../assets/img/contact3.png";

export default function Contact() {
  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">联系我们</h1>
            <p className="font13">
              宇轩公司/广恒达公司位于天津市宁河县潘庄工业园，从2013年五月份开始进行铸造试运行，占地180 亩，紧邻205国道，东临津唐高速公路，距离天津市区20公里，距离港口65公里，距空港15公里，距铁路40公里，地理位置利于生产用的原材料运入，产品的运出。
              <br />
              The company is located in Panzhuang Industrial Park, Ninghe County, Tianjin City. It began casting trial operation in May 2013. It covers an area of 180 mu, is close to National Highway 205, and is adjacent to Jintang Expressway in the east, 20 km from Tianjin City and 65 km from the port. Kilometers, 15 kilometers from the airport and 40 kilometers from the railway. The location is conducive to the import of raw materials for production and the export of products.
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form>
                <label className="font13">电话:</label>
                <input type="text" id="fname" name="fname" className="font20 extraBold" value={"26337331"} disabled={true}/>
                <label className="font13">手机:</label>
                <input type="text" id="phone" name="phone" className="font20 extraBold" value={"16622023903 尚荣"} disabled={true}/>
                <label className="font13">Email:</label>
                <input type="text" id="email" name="email" className="font20 extraBold" value={"rong.shang@tjyuxuankeji.com"} disabled={true}/>
              </Form>
              <SumbitWrapper className="flex">
                {/*<ButtonInput type="submit" value="Send Message" className="pointer animate radius8" style={{ maxWidth: "220px" }} />*/}
              </SumbitWrapper>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
              <div style={{ width: "50%" }} className="flexNullCenter flexColumn">
                <ContactImgBox>
                  <img src={ContactImg1} alt="office" className="radius6" />
                </ContactImgBox>
                <ContactImgBox>
                  <img src={ContactImg2} alt="office" className="radius6" />
                </ContactImgBox>
              </div>
              <div style={{ width: "50%" }}>
                <div style={{ marginTop: "100px" }}>
                  <img src={ContactImg3} alt="office" className="radius6" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #7620ff;
  background-color: #7620ff;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;









