import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Components
import TestimonialBox from "../Elements/TestimonialBox";

export default function TestimonialSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="天津宇轩科技有限公司生产设备精良，技术专业，产品一次交验合格率高，交货达成率，是个可靠的合作伙伴."
            author="申克（天津）工业技术有限公司"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="专业，靠谱。产品质量严格把控，积极探索科技创新。在与天津宇轩科技有限公司合作过程中，双方都得到了成长"
            author="卓轮(天津)机械有限公司"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="生产的设备精良，服务态度好。公司生产车间优秀，为天津宇轩科技有限公司全体员工的职业素养点赞！"
            author="西伯瑞制动器（天津）有限公司"
          />
        </LogoWrapper>

      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
