import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import BlogText from "../Elements/BlogText";
import TestimonialSlider from "../Elements/TestimonialSlider";
import Partner1 from "../../assets/img/partner/flender.png";
import Partner2 from "../../assets/img/partner/otis.png";
import Partner3 from "../../assets/img/partner/sements.jpg";
import Partner4 from "../../assets/img/partner/watts.jpg";
import Partner5 from "../../assets/img/partner/zf.png";
import Partner6 from "../../assets/img/partner/sew.png";

export default function Blog() {
  return (
    <Wrapper id="blog">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">合作伙伴</h1>
            <p className="font13">
              <br />
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="弗兰德传动系统有限公司"
                text={Partner1}
                author="Flender Ltd., China "
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="奥的斯电梯曳引机有限公司"
                text={Partner2}
                tag="company"
                author="Otis Elevator Traction Machine Co, Ltd."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="西门子数控有限公司天津分公司"
                text={Partner3}
                tag="company"
                author="Siemens Numerical Control Ltd., Nanjing Tianjin Branch"
                action={() => alert("clicked")}
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="沃茨阀门（宁波）有限公司"
                text={Partner4}
                tag="company"
                author="Watts Valve (Ningbo) Co.,Ltd"
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="采埃孚（天津）风电有限公司"
                text={Partner5}
                tag="company"
                author="ZF Wind Power (Tianjin) Co., Ltd"
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="SEW-传动设备(天津)有限公司"
                text={Partner6}
                tag="company"
                author="SEW-EURODRIVE（TIANJIN）CO.,LTD"
                action={() => alert("clicked")}
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogText
                  title="申克（天津）工业技术有限公司"
                  author="Schenck Process (Tianjin) Industrial Technology Co. Ltd."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogText
                  title="卓轮(天津)机械有限公司"
                  author="Zollern (Tianjin) Machine CO.,LTD"
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogText
                  title="西伯瑞制动器（天津）有限公司"
                  author="Gamesa Wind (Tianjin) Co.,Ltd"
              />
            </div>

          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogText
                  title="SEW-电机（苏州）有限公司"
                  author="Sew-motor (Suzhou) Co. , Ltd."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogText
                  title="日本岛津（天津）液压有限公司"
                  author="Shimadzu (Tianjin) Hydraulic Co. , Ltd"
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogText
                  title="中车福伊特传动技术（北京）有限公司"
                  author="CRRC Voith Transmission Technology (Beijing) Co., Ltd"
              />
            </div>

          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogText
                  title="派克特精液压(天津）有限公司"
                  author="Parker Tejing Hydraulics (Tianjin) Co.,Ltd"
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogText
                  title="东海橡塑（天津）有限公司"
                  author="Donghai rubber and plastic"
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogText
                  title="天津华盛昌齿轮有限公司"
                  author="Tianjin Huasheng Chang Gear Co. , Ltd."
              />
            </div>

          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogText
                  title="天津松本机械有限公司"
                  author="Tianjin Songben Machinery Co. , Ltd."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogText
                  title="天津工业泵集团有限公司"
                  author="Tianjin Industrial Pump Group Co. , Ltd."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogText
                  title="美国哈斯数控机床有限公司"
                  author="America Hass"
              />
            </div>

          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              {/*<FullButton title="Load More" action={() => alert("clicked")} />*/}
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg" style={{padding: '50px 0'}}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">合作伙伴怎么说?</h1>
            <p className="font13">
              <br />
            </p>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;